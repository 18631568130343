<template>
  <div>
    <DocumentAJoindre
      :context="{
        ID_Structure: structureID,
        ID_Saison: selectedSaison.id,
        hasCodeFederal,
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import documentAffiliation from "@/components/Affiliations/Affiliation_StructureDocUpload.vue";
import DocumentAJoindre from "@/components/GestionDesDocuments/GestionDesDocuments.vue";

export default {
  components: { DocumentAJoindre },

  data: () => ({
    mounted: false,
    structureID: null,
  }),

  async created() {
    this.mounted = true;
    this.setData();
    return;
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
    ...mapGetters("saisons", ["selectedSaison"]),
  },

  methods: {
    setData() {
      this.structureID = parseInt(this.$route.params.id);
      if(!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);
    },
  },
};
</script>
